<template>
  <div>
    <div class="chat-messages">
      <div v-for="message in chatMessages" :key="message.id" class="message-container">
        <div v-if="message.role === 'user'" class="user-message">{{ message.content }}</div>
        <div v-if="message.role === 'assistant'" class="assistant-message">{{ message.content }}</div>
      </div>
    </div>
    <div class="input-container">
      <input v-model="userMessage" type="text" placeholder="Enter a message" class="input-field">
      <button @click="sendMessage" class="send-button">Send</button>
    </div>
  </div>
</template>

<style>
.chat-messages {
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
}

.message-container {
  margin-bottom: 10px;
}

.user-message {
  background-color: #e0e0e0;
  color: #000;
  padding: 10px;
  border-radius: 8px;
}

.assistant-message {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  border-radius: 8px;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.input-field {
  flex: 1;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.send-button {
  background-color: #007bff;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  margin-left: 10px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #0056b3;
}
</style>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      chatMessages: [],
      userMessage: ''
    };
  },
  methods: {
    async sendMessage() {
      const apiKey = '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';
      const apiUrl = 'https://api.openai.com/v1/chat/completions';

      axios.defaults.headers.common['Authorization'] = `Bearer ${apiKey}`;

      try {
        // 发送用户输入的消息
        this.chatMessages.push({ role: 'user', content: this.userMessage });

        const response = await axios.post(apiUrl, {
          model: 'gpt-3.5-turbo',
          messages: this.chatMessages,
          temperature: 0.7
        });

        // 添加助手的回复到聊天消息
        const assistantReply = response.data.choices[0];
        this.chatMessages.push({
          role: 'assistant',
          content: assistantReply.message.content
        });

        // 清空用户输入
        this.userMessage = '';

        // 滚动到底部
        this.$nextTick(() => {
          const chatContainer = document.querySelector('.chat-messages');
          chatContainer.scrollTop = chatContainer.scrollHeight;
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
