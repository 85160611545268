<template>
  <div id="app">
    <div v-if="!showHelloWorld">
      <label for="password">请输入密码：</label>
      <input type="password" id="password" v-model="password">
      <button @click="checkPassword">确认</button>
    </div>
    <HelloWorld v-if="showHelloWorld" msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  data() {
    return {
      password: '',
      showHelloWorld: false
    }
  },
  methods: {
    checkPassword() {
      if (this.password === '1996') {
        this.showHelloWorld = true
      } else {
        alert('密码错误，请重新输入！')
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>